import React from 'react'
import classNames from 'classnames'
import Layout from 'layout/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import ChargerItem from 'components/ChargerItem/ChargerItem'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Parallax from 'components/Parallax/Parallax.js'
import landingPageStyle from 'assets/jss/styles/pages/landingPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import LandingServices from 'pages-sections/landing/LandingServices'
import Cargador from "assets/img/sections/cargador-coche-electrico.jpg";
import Bia from "assets/img/sections/bia.png";

const useStyles = makeStyles(landingPageStyle);

const Cargadores = ({ data }) => {
  const classes = useStyles()

  return (
    <Layout
      pageTitle="🔌 Cargadores para coches eléctricos en Barcelona provincia"
      pageDescription="✨ Instaladores de cargadores de coches eléctricos en Barcelona provincia. Elige el modelo que más te guste y nos encargamos del resto. Entra para ver modelos y precios. Trabajamos solo con los mejores cargadores de baterías de coche."
      headerImage={Cargador}
      filter="dark"
      content={
        <React.Fragment>
          <h1 className={classes.typist}>Instalación de cargadores para coches eléctricos</h1>

          <Link to="/presupuesto">
            <Button color={'primary'} className={classes.navButton}>
              Solicita Presupuesto
            </Button>
          </Link>

          <br />
        </React.Fragment>
      }
    >
      <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.mrBottom
          )}
        >
        <div dangerouslySetInnerHTML={{__html: data.cargadoresJson.content.childMarkdownRemark.html}} />
        <h3 style={{marginTop: "60px"}}><b>Conectamos los cargadores con Bia - Smart Charging</b></h3>
        <a href="https://www.biapower.io/" target="_blank" rel="noopener noreferrer">
          <img 
            src={Bia}
            alt="BiaPower"
            title="BiaPower"
          />
        </a>
        <p style={{marginTop: "30px"}}>Ofrecemos la posibilidad de conectar los cargadores con {" "}
          <a href="https://www.biapower.io/" target="_blank" rel="noopener noreferrer"> Bia </a>{" "}
          para reducir los costes eléctricos. Bia analiza las horas donde la energía sale más barata y optimiza la carga de los coches para que sea lo más económica posible. O, dicho de una forma más técnica, reduce la potencia pico agregada, de modo que se reduce el factor de potencia total, acelerando la amortización.
        </p>
      </GridItem>

      {data.cargadoresJson.chargers.map((obj, i) => {
        return (          
          <ChargerItem 
            key={i}
            data={obj}
            preview
          />          
        )
      })}

      <LandingServices classes={classes} />

    </Layout>
  )  
}

export default Cargadores

export const query = graphql`
  query CargadorespageQuery {
    cargadoresJson {
      content {
        childMarkdownRemark {
          html
        }
      }
      chargers {
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        description
        alt
        slug
        images {
          childImageSharp {
            fixed(height: 175) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }    
  }
`;